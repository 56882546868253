import _ from 'lodash';
import emoji from 'node-emoji';

const MAX_SLUG_LENGTH = 50;

export const slugify = (text: string | undefined) =>
  _.chain(text)
    .truncate({ length: MAX_SLUG_LENGTH, omission: '' })
    .thru(emoji.strip)
    .kebabCase();
